import React, { useEffect, useState } from "react";
import Parser from "rss-parser";

import "./LatestYoutube.scss";

const getYTId = url => {
  return url.slice(-11);
};

const getYTThumbnail = id => {
  return `https://i3.ytimg.com/vi/${id}/maxresdefault.jpg`;
};

const LatestYouTube = ({ channel, limit = 10 }) => {
  const [videos, setVideos] = useState([]);
  const CORS = "https://serene-gorge-35784.herokuapp.com/";

  useEffect(() => {
    const url = `${CORS}https://www.youtube.com/feeds/videos.xml?channel_id=${channel}`;
    const parser = new Parser();
    parser.parseURL(url).then(feed => {
      const { items } = feed;
      const videos = items.slice(0, limit);
      setVideos(videos);
    });
  }, [channel, limit]);

  return (
    <div className="latest-youtube">
      <div className="wrapper">
        <h4>
          <a
            href="https://www.youtube.com/c/emersonbroga/?sub_confirmation=1"
            target="_blank"
            rel="noreferrer"
          >
            Meus últimos Videos:
          </a>
        </h4>
        <ul className={videos.length === 1 ? "single" : "multiple"}>
          {videos.map(video => {
            return (
              <li key={video.id}>
                <a href={video.link} target="_blank" rel="noreferrer">
                  <div className="image-wrapper">
                    <div className="image">
                      <img
                        loading="lazy"
                        src={getYTThumbnail(getYTId(video.link))}
                        alt={video.title}
                      />
                    </div>
                  </div>
                  <span className="info">{video.title}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default LatestYouTube;
