import React, { useState, useEffect } from "react";
import CTA from "src/components/CTA";
import SEO from "src/components/SEO";
import Social from "src/components/Social";
import LatestYouTube from "src/components/LatestYouTube";

import { NAME, BASE_URL, YOUTUBE_CHANNEL_ID } from "src/helpers/config";
import { getRandomGroup } from "src/helpers/whatsapp";
import "./links.scss";

const linkList = [
  // {
  //   id: 1,
  //   link: "https://js.emersonbroga.com/react/",
  //   title: "Inscrições abertas",
  // },
  {
    id: 2,
    link: "https://js.emersonbroga.com/inscricoes-abertas/",
    title: "Curso de JavaScript",
  },
  {
    id: 3,
    link: "https://emersonbroga.com/es6",
    title: "Meu Livro sobre ES6 (hotmart)",
  },
  {
    id: 7,
    link: "https://emersonbroga.com/livro",
    title: "Meu Livro sobre ES6 (amazon)",
  },
  {
    id: 4,
    link: null,
    title: "Grupo do WhatsApp",
  },
  {
    id: 5,
    link: "https://t.me/jsdevils",
    title: "Grupo do Telegram",
  },
  {
    id: 6,
    link: "https://emersonbroga.com/sobre",
    title: "Quem é Emerson Broga?",
  },
  {
    id: 7,
    link: "https://bit.ly/100_do",
    title: "Cupom de U$100 na Digital Ocean",
  },
];

const Links = () => {
  const seoProps = {
    title: `Emerson Broga - ${NAME}`,
    description: `Ajudando programadores em suas carreiras, ensinando JavaScript, React e NodeJS!`,
    canonical: `${BASE_URL}/links/`,
    image: `${BASE_URL}/meta/og-image-default.jpg`,
  };

  const [links, setLinks] = useState(linkList);

  useEffect(() => {
    const newLinks = linkList.map(l => {
      if (l.id === 4) l.link = getRandomGroup();
      return l;
    });
    setLinks(newLinks);
  }, []);

  return (
    <div className="links sunset">
      <SEO {...seoProps} />
      <div className="inner-container">
        <div className="logo">
          <img
            loading="lazy"
            src="https://emersonbroga.com/e/assets/emersonbroga-logo-name-white.png"
            alt="Emerson Broga Logo"
          />
        </div>
        {/* <div className="js">
          <p style={{ textAlign: "center" }}>
            Venha participar gratiutamente da&nbsp;
            <strong style={{ color: "white" }}>Maratona JS 3</strong>, um evento
            100% online e 100% gratuito que acontecerá nos dias&nbsp;
            <strong style={{ color: "white" }}>13 à 17 de Setembro</strong>
          </p>
          <YoutubeVideo id={videoId} />
          <p style={{ textAlign: "center" }}>Se inscreva abaixo e participe</p>
          <Subscribe
            title="Quero participar gratuitamente"
            secondaryTitle="Quero participar gratuitamente"
            formId={CONVERTKIT_FORM_ID_SEP_2021}
            isModal={true}
          />
        </div> */}

        <div className="js">
          <p>
            Cadastre-se abaixo para receber mais informações e ser avisado de
            novos conteúdos e promoções e também das novas turmas dos Cursos.
          </p>
          <CTA id="newsletter" />
        </div>
        <br />
        <span>Veja minhas redes sociais</span>
        <Social type="light" />
      </div>
      <ul className="list">
        {links.map(l => (
          <li key={l.id}>
            <a href={l.link} target="_blank" rel="noopener noreferrer">
              {l.title}
            </a>
          </li>
        ))}
      </ul>
      <LatestYouTube channel={YOUTUBE_CHANNEL_ID} />
    </div>
  );
};
export default Links;
